import {Component, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SearchRequest} from "@portal/api/requests/search-request";
import {BrandsService} from '@portal/api/services/brands.service';
import {EcuProducersService} from "@portal/api/services/ecu-producers.service";
import {EcuTypesService} from "@portal/api/services/ecu-types.service";
import {StorageService} from "@portal/shared/services/storage.service";

@Component({
    selector: 'app-homepage',
    templateUrl: './homepage.component.html',
    styleUrls: ['./homepage.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HomepageComponent {

    constructor(private route: ActivatedRoute,
                protected fb: FormBuilder,
                private _router: Router,
                private _ecuProducerService: EcuProducersService,
                private _ecuTypesService: EcuTypesService,
                private _brandsService: BrandsService,
                private _storageService: StorageService) {
    }

    isLoadingSpinnerActive: boolean;
    form: FormGroup;
    innerPage: boolean;
    ecuProducerOptions: { label: string, value: string }[] = []
    brandOptions: { label: string, value: string }[] = []
    ecuTypeOptions: { label: string, value: string }[] = []

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            let page = params['p'];
            if (page === 'inner') {
                this.innerPage = true;
            }
        });
        this.form = this.fb.group({
            ecuTypeId: [null, [Validators.required]],
            brandId: [null, [Validators.required]],
            ecuProducerId: [null, [Validators.required]],
        });

        this._brandsService.list({
            getAll: true
        } as SearchRequest).subscribe(brands => {
            // order by name
            brands.items.sort((a, b) => a.name.localeCompare(b.name));
            this.brandOptions = brands.items.map(brand => {
                return {
                    label: brand.name,
                    value: brand.id.toString()
                };
            });
        });

        this._ecuProducerService.list({
            getAll: true
        } as SearchRequest).subscribe(ecuProducers => {
            // order by name
            ecuProducers.items.sort((a, b) => a.name.localeCompare(b.name));
            this.ecuProducerOptions = ecuProducers.items.map(ecuProducer => {
                return {
                    label: ecuProducer.name,
                    value: ecuProducer.id.toString()
                };
            });
        });
    }

    ecuProducerChange(value: any) {
        this._ecuTypesService.list({
            getAll: true,
            filters: [{code: 'ecuproducerid', values: [value.value]}]
        } as SearchRequest).subscribe(ecuTypes => {
            // order by name
            ecuTypes.items.sort((a, b) => a.name.localeCompare(b.name));
            this.ecuTypeOptions = ecuTypes.items.map(ecuType => {
                return {
                    label: ecuType.name,
                    value: ecuType.id.toString()
                };
            });
        });
    }

    search() {
        this.isLoadingSpinnerActive = true;
        if (this.form.valid) {
            this.isLoadingSpinnerActive = false;
            this._storageService.addCurrentSearchData(this.form.value);
            this._storageService.addCurrentSearchNameData({
                brand: this.brandOptions.find(x => x.value === this.form.get('brandId').value).label,
                ecuProducer: this.ecuProducerOptions.find(x => x.value === this.form.get('ecuProducerId').value).label,
                ecuType: this.ecuTypeOptions.find(x => x.value === this.form.get('ecuTypeId').value).label
            });
            this._router.navigate(['/search']);
        }
    }
}
