import {ApiBaseService} from "@portal/api/api-base-service";
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {UserAddRequest} from "@portal/api/requests/users/user-add-request";
import {Observable} from "rxjs";
import {Guid} from "guid-typescript";
import {ListResponse} from "@portal/api/responses/api-response";
import {SearchRequest} from "@portal/api/requests/search-request";
import {UserListResponse} from "@portal/api/responses/users/user-list-response";
import { UserUpdateRequest } from "../requests/users/user-update-request";
import {BrandAddRequest} from "@portal/api/requests/brands/brand-add-request";
import {BrandUpdateRequest} from "@portal/api/requests/brands/brand-update-request";
import {BrandListResponse} from "@portal/api/responses/brands/brand-list-response";
import {EcuProducerAddRequest} from "@portal/api/requests/ecu-producer/ecu-producer-add-request";
import {EcuProducerUpdateRequest} from "@portal/api/requests/ecu-producer/ecu-producer-update-request";
import {EcuProducerListResponse} from "@portal/api/responses/ecu-producer/ecu-producer-list-response";

@Injectable({
    providedIn: 'root'
})
export class EcuProducersService extends ApiBaseService {
    controller = 'ecu-producers';

    constructor(
        private _httpClient: HttpClient,
    ) {
        super(_httpClient);
    }

    add(obj: EcuProducerAddRequest): Observable<Guid> {
        return this.post<Guid, EcuProducerAddRequest>(obj, this.controller);
    }

    update(obj: EcuProducerUpdateRequest): Observable<Guid> {
        return this.put<Guid, EcuProducerUpdateRequest>(obj, this.controller);
    }

    getFormEdit(id: Guid): Observable<EcuProducerUpdateRequest> {
        return this.get<EcuProducerUpdateRequest>(this.controller + `/form/${id}`);
    }

    list(obj: SearchRequest): Observable<ListResponse<EcuProducerListResponse[]>> {
        return this.post<ListResponse<EcuProducerListResponse[]>, SearchRequest>(obj, this.controller + `/list`);
    }

    deleteEcuProducer(id: Guid): Observable<boolean> {
        return this.delete<boolean>(`${this.controller}/${id}`);
    }
}
