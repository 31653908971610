import {Guid} from "guid-typescript";
import {BehaviorSubject, catchError, finalize, Observable, of} from "rxjs";
import {ListService} from "@portal/api/services/interfaces/list.service";
import {SearchRequest} from "@portal/api/requests/search-request";
import {ListResponse} from "@portal/api/responses/api-response";
import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {SearchRequestFilter} from "@portal/api/requests/search-request-filter";

interface Identifiable {
    id: Guid;
}


export class BaseDataSource<T extends Identifiable> implements DataSource<T> {

    private dataSubject = new BehaviorSubject<T[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();
    public totalItems: number;
    public data: T[];

    constructor(private service: ListService) {
    }

    connect(collectionViewer: CollectionViewer): Observable<T[]> {
        return this.dataSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.dataSubject.complete();
        this.loadingSubject.complete();
    }

    update(item: T) {
        const data = this.dataSubject.getValue();
        if (data?.length > 0) {
            const index = data.findIndex(item => item.id === item.id);
            if (index !== -1) {
                const updatedData = [...data];
                updatedData[index] = item as any;
                this.dataSubject.next(updatedData);
            }
        }
    }

    add(item: T) {
        const data = this.dataSubject.getValue();
        if (data?.length === 0) {
            return;
        }
        const updatedData = [item, ...data];
        this.dataSubject.next(updatedData);
    }

    remove(id: Guid) {
        const data = this.dataSubject.getValue();
        if (data?.length === 0) {
            return;
        }
        const updatedData = data.filter(item => item.id !== id);
        this.dataSubject.next(updatedData);
    }

    load(
        sortDirection = 'asc',
        sortField = '',
        pageIndex = 0,
        pageSize = 10,
        refresh = true,
        filters: SearchRequestFilter[] = [],
        getAll = false) {

        if (this.dataSubject.getValue().length > 0 && !refresh) {
            return;
        }

        // Clonar os filtros
        let clonedFilters = JSON.parse(JSON.stringify(filters));

        this.loadingSubject.next(true);

        this.service.list<T>(
            {
                page: pageIndex,
                pageSize: pageSize,
                sortDirection: sortDirection === 'asc' ? 0 : 1,
                sortField: sortField,
                filters: clonedFilters,
                getAll: getAll
            } as SearchRequest).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        ).subscribe((response: ListResponse<T[]>) => {
            if (response) {
                this.dataSubject.next(response.items);
                this.totalItems = response.totalItems;
                this.data = response.items;
            }
        });
    }
}
