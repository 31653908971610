import {
    AfterViewInit,
    Component,
    ElementRef,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
    ViewEncapsulation
} from '@angular/core';
import {Router} from "@angular/router";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {DtcModuleService} from "@portal/api/services/dtc-module-service";
import {StorageService} from "@portal/shared/services/storage.service";
import {SearchModel} from "@portal/shared/models/search-model";
import {catchError, of} from "rxjs";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {MatChipInputEvent} from "@angular/material/chips";
import {SignatureData} from "@portal/api/responses/dtc-removal/signature-data";
import {MapType} from "@portal/api/enums/map-type";
import {MatDialog} from "@angular/material/dialog";
import {DeleteDialogComponent} from "@portal/shared/delete-dialog/delete-dialog.component";
import {EnumNamesService} from "@portal/shared/services/enum-names.service";
import {reflectTypeEntityToDeclaration} from "@angular/compiler-cli/src/ngtsc/reflection";

@Component({
    selector: 'app-dtc-removal',
    templateUrl: './dtc-removal.component.html',
    styleUrls: ['./dtc-removal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DtcRemovalComponent implements OnInit, AfterViewInit {
    data: any;
    dataName: SearchModel;
    form: FormGroup;
    formErrors: FormGroup;
    filePassed: boolean;
    @ViewChild('fileInput') fileInput: ElementRef;
    fileName: string = '';
    isLoadingSpinnerActive: boolean;
    errors: string[] = [];
    signatureData: SignatureData;
    readonly separatorKeysCodes = [ENTER, COMMA] as const;
    isAdmin: boolean;
    @ViewChildren('mapContainer') mapContainers: QueryList<ElementRef<HTMLDivElement>>;
    private scrollMapping = new Map<EventTarget, ElementRef<HTMLDivElement>>();
    lastErrorClicked: string;
    lastErrorIndexScrolled: number;

    constructor(
        public dialog: MatDialog,
        private _router: Router,
        protected fb: FormBuilder,
        private _enumService: EnumNamesService,
        private _dtcModuleService: DtcModuleService,
        private _storageService: StorageService) {
    }

    ngAfterViewInit(): void {
        this.mapContainers.forEach((container) => {
            this.scrollMapping.set(container.nativeElement, container);
        });
    }

    ngOnInit(): void {
        this.isAdmin = this._storageService.isAdmin();
        this.filePassed = false;
        this.data = this._storageService.getCurrentSearchData();
        this.dataName = this._storageService.getCurrentSearchNameData();
        if (this.data === null) {
            this._router.navigate(['/']);
        }
        this.form = this.fb.group({
            file: new FormControl(null, [Validators.required]),
        });
        this.formErrors = this.fb.group({
            errors: [null, [Validators.required]],
        });
    }

    onFileSelected(event: Event): void {
        const file = (event.target as HTMLInputElement).files[0];
        if (file) {
            this.fileName = file.name;  // Update the UI to show the file name
            this.form.get('file').setValue(file);
        }
    }

    triggerFileInput(): void {
        this.fileInput.nativeElement.click();  // Trigger the file input dialog
    }

    removeError(error: string): void {
        const index = this.errors.indexOf(error);

        if (index >= 0) {
            this.errors.splice(index, 1);
        }
        // remove the isSelect value from the hex values
        const descriptionsTable = this.signatureData.maps.find(x => x.mapType === MapType.DtcDescriptions);
        descriptionsTable.hexValues.forEach(element => {
            if (element.value === error) {
                element.isSelect = false;
                element.isScrolled = false;
            }
        });
    }

    onErrorClick(error: string): void {
        if (this.lastErrorClicked !== error) {
            this.lastErrorIndexScrolled = null;
        }

        const descriptionsTable = this.signatureData.maps.find(x => x.mapType === MapType.DtcDescriptions);
        const descriptions = descriptionsTable.hexValues.map((value) => value.value);
        let found = false;
        let firstFoundIndex = null;
        let lastFoundIndex = null;
        let foundIndex = descriptions.findIndex((element, i) => {
            if (element === error) {
                if (firstFoundIndex === null) {
                    firstFoundIndex = i;
                }
                if (this.lastErrorIndexScrolled === null || i > this.lastErrorIndexScrolled) {
                    return true;
                }
            }
            return false;
        });

        if (foundIndex !== -1) {
            found = true;
            if (this.lastErrorIndexScrolled === null) {
                firstFoundIndex = foundIndex;
            } else {
                lastFoundIndex = foundIndex;
            }
        } else {
            found = true;
            foundIndex = firstFoundIndex;
        }
        if (lastFoundIndex === null) {
            lastFoundIndex = firstFoundIndex;
        }
        if (found) {
            this.mapContainers.forEach((container) => {
                const target = container.nativeElement.children[0] as HTMLElement;
                const targetElement = target.children[foundIndex] as HTMLElement;

                // Container dimensions
                const containerWidth = container.nativeElement.clientWidth;

                // Element dimensions and position
                const elementWidth = targetElement.clientWidth;
                const elementLeft = targetElement.offsetLeft;
                const elementCenter = elementLeft + elementWidth / 2;

                // Calculate the scroll position needed to center the element
                const scrollLeft = Math.max(0, elementCenter - containerWidth / 2);

                // Apply the scroll position
                container.nativeElement.scrollLeft = scrollLeft
            });
            this.lastErrorClicked = error;
            this.lastErrorIndexScrolled = lastFoundIndex;
            // set hex values to isScrolled true on foundIndex
            descriptionsTable.hexValues.forEach((element, i) => {
                if (i === foundIndex) {
                    element.isScrolled = true;
                } else {
                    element.isScrolled = false;
                }
            });
        }

    }

    addError(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();

        const descriptionsTable = this.signatureData.maps.find(x => x.mapType === MapType.DtcDescriptions);
        const descriptions = descriptionsTable.hex.split(' ');
        let found = false;
        descriptions.forEach(element => {
            if (element === value) {
                found = true;
            }
        });

        if (!found) {
            this.dialog.open(DeleteDialogComponent, {
                data: {
                    title: 'Error not found',
                    hasOkButton: true
                }
            });
            event.chipInput!.clear();
            return;
        }

        if (value) {
            this.errors.push(value);
            // set the value to the form control joined by a comma
            this.formErrors.get('errors').setValue(this.errors.join(','));
            // find the index of the value in the descriptions hex and set it to selected
            descriptionsTable.hexValues.forEach(element => {
                if (element.value === value) {
                    element.isSelect = true;
                }
            });
        }

        // Clear the input value
        event.chipInput!.clear();
    }

    getMapTypeName(mapType: MapType): string {
        return this._enumService.getMapTypeName(mapType);
    }

    syncScroll(event: Event): void {
        const source = event.target as HTMLDivElement;
        const {scrollLeft} = source;

        this.mapContainers.forEach((container) => {
            const target = container.nativeElement;
            if (target !== source) {
                target.scrollLeft = scrollLeft;
            }
        });
    }

    save() {
        if (this.filePassed) {
            if (this.formErrors.valid) {
                const formData = new FormData();
                formData.append('errors', this.formErrors.get('errors').value);
                formData.append('brandId', this.data.brandId);
                formData.append('ecuProducerId', this.data.ecuProducerId);
                formData.append('ecuTypeId', this.data.ecuTypeId);
                formData.append('file', this.form.get('file').value);

                // submit and download the file
                this.isLoadingSpinnerActive = true;

                this._dtcModuleService.submit(formData).pipe(
                    // Handle errors
                    catchError((error: any) => {
                        this.isLoadingSpinnerActive = false;

                        // Log error
                        console.error('Submission error:', error);

                        // Handle specific error codes (e.g., 400, 500, etc.)
                        if (error.status === 400) {
                            // add a dialog with error message
                            alert('Invalid data.');
                        } else if (error.status === 415) {
                            alert('Unsupported Media Type: Please upload a valid file format.');
                        } else {
                            alert('An error occurred while submitting the form. Please try again.');
                        }

                        // Return an empty observable to stop further processing
                        return of(null);
                    })
                ).subscribe((response) => {
                    this.isLoadingSpinnerActive = false;

                    if (response.type === 4) {
                        const fileName = `${this.fileName}_errors_${this.formErrors.get('errors').value}.bin`;
                        const blob = new Blob([response.body], {type: 'application/octet-stream'});
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = fileName;
                        a.click();
                        window.URL.revokeObjectURL(url);
                        this._storageService.clearCurrentSearchData();
                        this._router.navigate(['/']);
                    }
                });
            }
        } else {
            if (this.form.valid) {
                const formData = new FormData();
                formData.append('brandId', this.data.brandId);
                formData.append('ecuProducerId', this.data.ecuProducerId);
                formData.append('ecuTypeId', this.data.ecuTypeId);
                formData.append('file', this.form.get('file').value);

                // submit and download the file
                this.isLoadingSpinnerActive = true;

                this._dtcModuleService.checkFile(formData).pipe(
                    // Handle errors
                    catchError((error: any) => {
                        this.isLoadingSpinnerActive = false;

                        // Handle specific error codes (e.g., 400, 500, etc.)
                        if (error.status === 400) {
                            // add a dialog with error message
                            this.dialog.open(DeleteDialogComponent, {
                                data: {
                                    title: 'Invalid data',
                                    hasOkButton: true
                                }
                            });
                        } else if (error.status === 415) {
                            this.dialog.open(DeleteDialogComponent, {
                                data: {
                                    title: 'Unsupported Media Type: Please upload a valid file format',
                                    hasOkButton: true
                                }
                            });
                        } else {
                            this.dialog.open(DeleteDialogComponent, {
                                data: {
                                    title: 'An error occurred while submitting the form. Please try again',
                                    hasOkButton: true
                                }
                            });
                        }

                        // Return an empty observable to stop further processing
                        return of(null);
                    })
                ).subscribe((response) => {
                    // process the response events here
                    if (response.type === 4) {
                        this.filePassed = true;
                        this.isLoadingSpinnerActive = false;
                        this.signatureData = response.body;
                        // split the hex values into an array
                        this.signatureData.maps.forEach(element => {
                            element.hexValues = element.hex.split(' ').map((value) => {
                                return {value, isSelect: false, isScrolled: false};
                            });
                        });
                    }
                });
            }
        }
    }
}
