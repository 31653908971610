import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ApiBaseService} from "@portal/api/api-base-service";
import {Observable} from "rxjs";
import {SearchRequest} from "@portal/api/requests/search-request";
import {ListService} from "@portal/api/services/interfaces/list.service";
import {ListResponse} from "@portal/api/responses/api-response";

@Injectable({
    providedIn: 'root'
})
export class SupportedSolutionsService  extends ApiBaseService implements ListService {
    controller = 'supported-solutions';
    constructor(
        private _httpClient: HttpClient,
    ) {
        super(_httpClient);
    }

    list<SupportedSolutionsResponse>(obj: SearchRequest): Observable<ListResponse<SupportedSolutionsResponse[]>> {
        return this.post<ListResponse<SupportedSolutionsResponse[]>, SearchRequest>(obj, this.controller + `/list`);
    }
}
