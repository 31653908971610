import {Injectable} from '@angular/core';
import {AuthToken} from '../../api/responses/Auth/auth-token';
import { jwtDecode } from 'jwt-decode';
import {UserInfoResponse} from "@portal/api/responses/users/user-info.response";
import {SearchModel} from "@portal/shared/models/search-model";

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    private authObjKey = 'authState';
    private partyKey = 'party';
    private orgKey = 'org';
    private userKey = 'currentUser';
    private lastUserKey = 'lastUser';

    getObject(key: string): any {
        return JSON.parse(localStorage.getItem(key));
    }

    setObject(key: string, obj: any): void {
        const item = localStorage.getItem(key);
        if (item !== null) {
            localStorage.removeItem(key);
        }

        localStorage.setItem(key, JSON.stringify(obj));
    }

    getCurrentUser(): UserInfoResponse {
        return this.getObject(this.userKey);
    }
    remove(key: string): void {
        localStorage.removeItem(key);
    }

    getAccessToken(): string {
        const auth = this.getObject(this.authObjKey) as AuthToken;
        if (auth) {
            return auth.accessToken;
        }
        return null;
    }

    getRefreshToken(): string {
        const auth = this.getObject(this.authObjKey) as AuthToken;
        if (auth) {
            return auth.refreshToken;
        }
        return null;
    }

    getItem(key: string): string {
        return localStorage.getItem(key);
    }

    setItem(key: string, item: string): void {
        localStorage.setItem(key, item);
    }

    getTokenRoles(): string[] {
        const token = this.getAccessToken();
        if (token) {
            const decodedToken: any = jwtDecode(token);
            return decodedToken["role"] || [];
        }
        return [];
    }

    isSuperAdmin(): boolean {
        return this.getTokenRoles().includes('SuperAdmin');
    }

    isAdmin(): boolean {
        return (this.getTokenRoles().includes('Manager') || this.getTokenRoles().includes('Admin'));
    }
    addCurrentSearchData(data: any): void {
        this.setObject('currentSearchData', data);
    }

    addCurrentSearchNameData(data: SearchModel): void {
        this.setObject('currentSearchDataName', data);
    }

    getCurrentSearchData(): any {
        return this.getObject('currentSearchData');
    }
    getCurrentSearchNameData(): SearchModel {
        return this.getObject('currentSearchDataName');
    }

    clearCurrentSearchData(): void {
        this.remove('currentSearchData');
        this.remove('currentSearchDataName');
    }

    clearCurrentSession(): void {
        this.remove('currentUser');
        this.remove('authState');
    }
}
