import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { AuthToken } from '../../api/responses/Auth/auth-token';
import { LoginResponse } from '../../api/responses/Auth/login-response';
import { StorageService } from '@portal/shared/services/storage.service';
import { Router } from '@angular/router';
import {UserInfoResponse} from "@portal/api/responses/users/user-info.response";

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    readonly authObjPath = 'authState';
    readonly currentUserPath = 'currentUser';
    readonly lastUserPath = 'lastUser';

    get loginStatus(): Observable<boolean> {
        return this.isLoggedIn.asObservable();
    }

    constructor(
        private router: Router,
        private _storageService: StorageService
    ) {
        this.setIsLoggedInFromStorage();
        this.listenLoginStatusOnOtherTabs();
    }

    private listenLoginStatusOnOtherTabs() {
        addEventListener(
            'storage', event => {
                if (event.key === this.authObjPath) {
                    if (event.newValue) {
                        this.isLoggedIn.next(true);
                        this.router.navigate(['/']);
                    } else {
                        this.isLoggedIn.next(false);
                        this.router.navigate(['/sign-in']);
                    }
                }
            }, false
        );
    }

    private setIsLoggedInFromStorage() {
        const isLoggedIn = this.verifyAuthenticated();
        this.isLoggedIn.next(isLoggedIn);
        return isLoggedIn;
    }

// -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set authToken(token: AuthToken) {
        this._storageService.setObject(this.authObjPath, token);
    }

    set user(user: UserInfoResponse) {
        this._storageService.setObject(this.currentUserPath, user);
        this._storageService.setObject(this.lastUserPath, user);
    }


    get accessToken(): string {
        return this._storageService.getAccessToken();
    }

    getAuth(): AuthToken {
        return this._storageService.getObject(this.authObjPath);
    }

    get refreshToken(): string {
        return this._storageService.getRefreshToken();
    }

    get isAuthenticated(): boolean {
        return this.isLoggedIn.getValue();
    }

    setUserLoggedIn(response: LoginResponse) {
        this.authToken = response.token;
        this.user = response.user;
        this.isLoggedIn.next(true);
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        // Remove the access token from the local storage
        localStorage.removeItem('authState');
        // Set the authenticated flag to false
        this.isLoggedIn.next(false);
        // Return the observable
        return of(true);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this.isAuthenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return of(false);
    }

    isTokenExpired(): boolean {
        return AuthUtils.isTokenExpired(this.accessToken);
    }

    private verifyAuthenticated(): boolean {
        if (!this.accessToken) {
            return false;
        }
        return !this.isTokenExpired();
    }

    setIsLoggedIn(value: boolean) {
        this.isLoggedIn.next(value);
    }
}
