import {Injectable} from "@angular/core";
import {ApiBaseService} from "@portal/api/api-base-service";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {SolutionSearchResponse} from "@portal/api/responses/solution-search/solution-search-response";
import {SolutionsSearchRequest} from "@portal/api/requests/solution-search/solutions-search-request";

@Injectable({
    providedIn: 'root'
})

export class SolutionSearchService extends ApiBaseService {
    controller = 'solution-search';

    constructor(
        private _httpClient: HttpClient,
    ) {
        super(_httpClient);
    }

    search(obj: SolutionsSearchRequest): Observable<SolutionSearchResponse> {
        return this.post<SolutionSearchResponse, SolutionsSearchRequest>(obj, this.controller + '/search');
    }
}
