import {Injectable} from "@angular/core";
import {ApiBaseService} from "@portal/api/api-base-service";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs";
import {SignatureData} from "@portal/api/responses/dtc-removal/signature-data";

@Injectable({
    providedIn: 'root'
})
export class DtcModuleService extends ApiBaseService {
    controller = 'dtc-module';

    constructor(
        private _httpClient: HttpClient,
) {
        super(_httpClient);
    }

    submit(formData : FormData): Observable<HttpResponse<Blob>> {
        return this.postMultipartOptions(`${this.controller}/submit`, formData, { responseType: 'blob' as 'json', observe: 'response'});
    }

    checkFile(formData : FormData): Observable<HttpResponse<SignatureData>> {
        // add check file endpoint multipart and process events to progress bar
        return this.postMultipartOptions(`${this.controller}/check-file`, formData, { responseType: 'json' as 'json', observe: 'events'});
    }
}
