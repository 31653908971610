<div class="flex flex-col items-center justify-center w-full container-h bg-landing">
    <div class="home-container flex flex-col items-center justify-center">
        <div class="flex justify-center text-3xl" *ngIf="result" [ngSwitch]="result.hasDtcRemoval">
            <div class="t-w" *ngSwitchCase="true">{{totalSolutions}} solutions(s) available</div>
            <div class="t-w" *ngSwitchCase="false">No solutions(s) available</div>
        </div>
        <div class="flex justify-center text-2xl font-bold mt-4 t-w" *ngIf="dataName && dataName.brand">
            {{dataName.brand}} {{ dataName.ecuProducer}} {{ dataName.ecuType}}
        </div>
        <div class="flex justify-center text-2xl font-bold" *ngIf="totalSolutions === 0">
            <button class="ml-4 text-2xl" mat-flat-button type="button" [color]="'primary'" routerLink="/">Back</button>
        </div>
        <div class="flex flex-col md:flex-row">
            <div class="flex justify-center p-6 w-full cursor-pointer" [routerLink]="['/dtc-removal']"
                 *ngIf="result && result.hasDtcRemoval">
                <div class="flex flex-col justify-center  card p-6 bg-white rounded-lg shadow-lg">
                    <h1 class="text-3xl font-bold text-gray-800">DTC Removal</h1>
                </div>
            </div>
            <div class="flex justify-center p-6 w-full cursor-pointer" [routerLink]="['/tuning']"
                 *ngIf="result && result.hasTuning">
                <div class="flex flex-col justify-center  card p-6 bg-white rounded-lg shadow-lg">
                    <h1 class="text-3xl font-bold text-gray-800">AI Tune</h1>
                </div>
            </div>
        </div>
    </div>
</div>
