import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {SolutionSearchService} from "@portal/api/services/solution-search-service";
import {HomeFormService} from "@portal/shared/services/home-form-service";
import {SolutionsSearchRequest} from "@portal/api/requests/solution-search/solutions-search-request";
import {SolutionSearchResponse} from "@portal/api/responses/solution-search/solution-search-response";
import {Router} from "@angular/router";
import {data} from "autoprefixer";
import {StorageService} from "@portal/shared/services/storage.service";
import {SearchModel} from "@portal/shared/models/search-model";

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SearchComponent implements OnInit {
    dataName: SearchModel;
    result: SolutionSearchResponse;
    totalSolutions: number;

    constructor(
        private _router: Router,
        private _searchService: SolutionSearchService,
        private _storageService: StorageService) {
    }

    ngOnInit(): void {
        const data = this._storageService.getCurrentSearchData();
        this.dataName = this._storageService.getCurrentSearchNameData();

        if (data === null) {
            this._router.navigate(['/']);
        } else {
            this._searchService.search({
                brandId: data.brandId,
                ecuProducerId: data.ecuProducerId,
                ecuTypeId: data.ecuTypeId
            } as SolutionsSearchRequest)
                .subscribe((response: SolutionSearchResponse) => {
                    this.result = response;
                    this.totalSolutions = 0;
                    if (response.hasDtcRemoval && response.hasTuning) {
                        this.totalSolutions = 2;
                    } else if (response.hasDtcRemoval || response.hasTuning) {
                        this.totalSolutions = 1;
                    }
                });
        }
    }

    protected readonly data = data;
}
