<div class="flex flex-col items-center justify-center w-full container-h bg-landing">
    <div class="home-container flex flex-col items-center justify-center">
        <div>
            <div class="text-4xl font-bold mb-4 t-w">AI Tune Editor</div>
        </div>
        <div>
            <div class="mb-8 t-w text-2xl">Introduce the files. We will take care of the rest.
            </div>
        </div>
        <div class="flex justify-center text-2xl font-bold t-w" *ngIf="dataName && dataName.brand">
            {{dataName.brand}} {{ dataName.ecuProducer}} {{ dataName.ecuType}}
        </div>
        <div class="flex justify-center p-6 w-full" *ngIf="!formSubmitted">
            <form class="w-full max-w-lg sm:max-w-xl md:max-w-2xl lg:max-w-2xl" [formGroup]="form">
                <div class="flex flex-col">
                    <div class="custom-file-input">
                        <input #originalFileInput type="file" hidden (change)="onFileSelected($event,'original')">
                        <button class="btn-file" mat-raised-button (click)="triggerFileInput('original')">
                            <mat-icon>attach_file</mat-icon>
                            Choose Original File
                        </button>
                        <span class="ml-2 t-w" *ngIf="originalFileName">{{ originalFileName }}</span>
                    </div>
                    <div class="custom-file-input mt-4">
                        <input #modifiedFileInput type="file" hidden (change)="onFileSelected($event,'modified')">
                        <button class="btn-file" mat-raised-button (click)="triggerFileInput('modified')">
                            <mat-icon>attach_file</mat-icon>
                            Choose Modified File
                        </button>
                        <span class="ml-2 t-w" *ngIf="modifiedFileName">{{ modifiedFileName }}</span>
                    </div>
                </div>
                <div class="flex items-center justify-center mt-4">
                    <button
                        class="ml-4 text-2xl btn-landing"
                        mat-flat-button
                        type="button"
                        [color]="'primary'"
                        (click)="save()"
                        [disabled]="!form.valid || isLoadingSpinnerActive">
                        <mat-spinner *ngIf="isLoadingSpinnerActive"></mat-spinner>
                        Submit
                    </button>
                </div>
            </form>
        </div>
        <div class="flex justify-center p-6 w-full" *ngIf="formSubmitted && sliders.length === 0">
            <div class="mb-8 t-w text-2xl">No modifications available for this file. Please try again with another file.</div>
        </div>
        <div class="flex justify-center p-6 w-full" *ngIf="formSubmitted && sliders.length > 0">
            <form class="p-6 w-full bg-card max-w-lg sm:max-w-xl md:max-w-2xl lg:max-w-2xl" [formGroup]="form">
                <div *ngFor="let slider of sliders">
                    <div class="text-2xl font-bold">{{slider.name}}</div>
                    <ngx-slider
                        [id]="slider.name"
                        [options]="options"
                        (valueChange)="sliderChange($event, slider.id)"
                    ></ngx-slider>
                </div>

                <div class="flex items-center justify-center mt-4">
                    <button
                        class="ml-4 text-2xl"
                        mat-flat-button
                        type="button"
                        [color]="'primary'"
                        (click)="submit()"
                        [disabled]="!form.valid || isLoadingSpinnerActive">
                        <mat-spinner *ngIf="isLoadingSpinnerActive"></mat-spinner>
                        Submit
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
