<div class="flex items-center">
    <!-- Button -->
    <button
        mat-icon-button
        [matMenuTriggerFor]="userActions">
    <span class="relative">
          <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
    </span>
    </button>
    <span *ngIf="user" style="line-height: 40px">
        Hi, {{ user.name }}
    </span>
</div>


<mat-menu
    [xPosition]="'before'"
    #userActions="matMenu">
    <button mat-menu-item *ngIf="user">
        <span class="flex flex-col leading-none">
            <span>Connected as</span>
            <span class="mt-1.5 text-md font-medium">{{user.email}}</span>
        </span>
    </button>

    <button *ngIf="user"
            mat-menu-item
            (click)="signOut()">
        <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
        <span>Logout</span>
    </button>
    <button *ngIf="!user"
            mat-menu-item
            [routerLink]="['/sign-in']">
        <mat-icon svgIcon="login"></mat-icon>
        <span>Sign-in</span>
    </button>
    <button *ngIf="!user"
            mat-menu-item
            [routerLink]="['/sign-up']">
        <mat-icon svgIcon="mat_outline:assignment"></mat-icon>
        <span>Register</span>
    </button>
</mat-menu>
