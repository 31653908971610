import {Injectable} from "@angular/core";
import { DataType } from "@portal/api/enums/data-type";
import {DtcType} from "@portal/api/enums/dtc-type";
import {UserRole} from "@portal/api/enums/user-role";
import {MapType} from "@portal/api/enums/map-type";

@Injectable({
    providedIn: 'root'
})

export class EnumNamesService {
    getDataTypeOptions(): Array<{ value: DataType, label: string }> {
        return Object.keys(DataType)
            .filter(key => !isNaN(Number(DataType[key]))) // exclude computed properties
            .map(key => {
                let label;
                switch (DataType[key]) {
                    case DataType.BIT8:
                        label = '8 bits';
                        break;
                    case DataType.BIT16LoHi:
                        label = '16 bits (Lo-Hi)';
                        break;
                    case DataType.BIT16HiLo:
                        label = '16 bits (Hi-Lo)';
                        break;
                    case DataType.BIT32LoHi:
                        label = '32 bits (Lo-Hi)';
                        break;
                    case DataType.BIT32HiLo:
                        label = '32 bits (Hi-Lo)';
                        break;
                    case DataType.FLOATHiLo:
                        label = 'Float (Hi-Lo)';
                        break;
                    case DataType.FLOATLoHi:
                        label = 'Float (Lo-Hi)';
                        break;
                }
                return {value: DataType[key], label: label};
            });
    }

    getDtcTypeOptions(): Array<{ value: DtcType, label: string }> {
        return Object.keys(DtcType)
            .filter(key => !isNaN(Number(DtcType[key]))) // exclude computed properties
            .map(key => {
                let label;
                switch (DtcType[key]) {
                    case DtcType.ReplaceSignature:
                        label = 'Signature';
                        break;
                    case DtcType.ReplaceValue:
                        label = 'Value';
                        break;
                }
                return {value: DtcType[key], label: label};
            });
    }
    getRolesOptions(): Array<{ value: UserRole, label: string }> {
        return Object.keys(UserRole)
            .filter(key => !isNaN(Number(UserRole[key]))) // exclude computed properties
            .map(key => {
                let label;
                switch (UserRole[key]) {
                    case UserRole.Admin:
                        label = 'Administrator';
                        break;
                    case UserRole.Manager:
                        label = 'Manager';
                        break;
                    case UserRole.User:
                        label = 'User';
                        break;
                }
                return {value: UserRole[key], label: label};
            });
    }
    getMapTypeOptions(): Array<{ value: MapType, label: string }> {
        return Object.keys(MapType)
            .filter(key => !isNaN(Number(MapType[key]))) // exclude computed properties
            .map(key => {
                let label = this.getMapTypeName(MapType[key])

                return {value: MapType[key], label: label};
            });
    }
    getDtcTypeName(value: number): string {
        switch (value) {
            case DtcType.ReplaceSignature:
                return 'Signature';
            case DtcType.ReplaceValue:
                return 'Replace Value';
        }
    }
    getMapTypeName(value: number): string {
        switch (value) {
            case MapType.DtcDescriptions:
                return 'DTC Descriptions';
            case MapType.DtcClasses:
                return 'DTC Classes';
            case MapType.DtcMasks:
                return 'DTC Masks';
        }
    }
}
