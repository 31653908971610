import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {map, Observable, ReplaySubject, shareReplay, tap} from 'rxjs';
import { User } from 'app/core/user/user.types';
import { StorageService } from '../../shared/services/storage.service';
import {UserInfoResponse} from "@portal/api/responses/users/user-info.response";

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _storageService: StorageService
    ) {

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User) {
        // Store the value
        this._user.next(value);
    }

    get user(): User {
        return this._storageService.getObject('currentUser') as User;
    }

    get user$(): Observable<User> {
        const userData = this._storageService.getObject('currentUser') as UserInfoResponse;
        if (userData){
            this._user.next({
                id: userData.id,
                name: `${userData.firstName} ${userData.lastName}`,
                email: userData.email
            });
        }
        return this._user.asObservable().pipe(shareReplay());
    }
    logout(){
        this._user.next(null);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in user data
     */
    get(): Observable<User> {
        return this._httpClient.get<User>('api/common/user').pipe(
            tap((user) => {
                this._user.next(user);
            })
        );
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: User): Observable<any> {
        return this._httpClient.patch<User>('api/common/user', { user }).pipe(
            map((response) => {
                this._user.next(response);
            })
        );
    }
}
