import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Subject, takeUntil} from 'rxjs';
import {FuseMediaWatcherService} from '@fuse/services/media-watcher';
import {FuseNavigationItem, FuseNavigationService, FuseVerticalNavigationComponent} from '@fuse/components/navigation';
import {StorageService} from "@portal/shared/services/storage.service";

@Component({
    selector: 'enterprise-layout',
    templateUrl: './enterprise.component.html',
    encapsulation: ViewEncapsulation.None
})
export class EnterpriseLayoutComponent implements OnInit, OnDestroy {
    isScreenSmall: boolean;
    navigation: FuseNavigationItem[] = [];
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _storageService: StorageService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        let menuItem: FuseNavigationItem = {
            title: 'Supported List',
            type: "basic",
            link: "/supported-list",
        };
        this.navigation.push(menuItem);
        const roles = this._storageService.getTokenRoles()
        if (roles.includes('Admin') || roles.includes('Manager')) {
            let adminItem: FuseNavigationItem = {
                title: 'Administration',
                type: "collapsable",
                children: [
                    {
                        title: 'Brands',
                        type: "basic",
                        link: "/admin/brands",
                        order: 1
                    },
                    {
                        title: 'Models',
                        type: "basic",
                        link: "/admin/models",
                        order: 2
                    },
                    {
                        title: 'Ecu producers',
                        type: "basic",
                        link: "/admin/ecu-producers",
                        order: 3
                    },
                    {
                        title: 'Ecu Types',
                        type: "basic",
                        link: "/admin/ecu-types",
                        order: 4
                    },
                    {
                        title: 'DTC Configuration',
                        type: "basic",
                        link: "/admin/dtc-configuration",
                        order: 5
                    },
                    {
                        title: 'Tuning Configuration',
                        type: "basic",
                        link: "/admin/tuning-configuration",
                        order: 6
                    },
                    {
                        title: 'Api Clients',
                        type: "basic",
                        link: "/admin/api-keys",
                        order: 7
                    },
                ]
            };

            if (roles.includes('Admin')) {
                adminItem.children.push({
                    title: 'Users',
                    type: "basic",
                    link: "/admin/users",
                    order: 0
                });
            }
            adminItem.children = adminItem.children.sort((a, b) => a.order - b.order);
            this.navigation.push(adminItem);

        }

        this
            ._fuseMediaWatcherService
            .onMediaChange$
            .pipe(takeUntil

            (
                this
                    ._unsubscribeAll
            ))
            .subscribe(
                ({
                     matchingAliases
                 }
                ) => {

                    // Check if the screen is small
                    this.isScreenSmall = !matchingAliases.includes('md');
                }
            )
        ;
    }

    /**
     * On destroy
     */
    ngOnDestroy()
        :
        void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

// -----------------------------------------------------------------------------------------------------
// @ Public methods
// -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name
                         :
                         string
    ):
        void {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }
}
