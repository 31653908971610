<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!--&lt;!&ndash; Wrapper &ndash;&gt;-->
<!--<div class="flex flex-col flex-auto w-full">-->

<!--    &lt;!&ndash; Content &ndash;&gt;-->
<!--    <div class="flex flex-col flex-auto">-->
<!--        &lt;!&ndash; *ngIf="true" hack is required here for router-outlet to work correctly.-->
<!--             Otherwise, layout changes won't be registered and the view won't be updated! &ndash;&gt;-->
<!--        <router-outlet *ngIf="true"></router-outlet>-->
<!--    </div>-->

<!--</div>-->

<router-outlet *ngIf="true"></router-outlet>
