<div class="flex flex-col items-center justify-center w-full container-h bg-landing">
    <div class="home-container flex flex-col items-center justify-center">
        <div>
            <div class="text-4xl font-bold mb-4 t-w">Your platform for vehicle software solutions</div>
        </div>
        <div>
            <div class="t-w text-2xl text-center">Openauto is a free, exclusive tool for chiptuners.
            </div>
            <div class="t-w text-2xl text-center">We carefully select our members to ensure a dedicated and skilled community.
            </div>
            <div class="t-w text-2xl text-center mb-8">Simply register to request access and enjoy features like DTC removal and editing AITune modified files.
            </div>
        </div>
        <div class="text-3xl t-w">
            Which vehicle is your solution for?
        </div>
        <div class="flex justify-center p-6 w-full">
            <form class="w-full max-w-lg sm:max-w-xl md:max-w-2xl lg:max-w-7xl" [formGroup]="form">
                <div class="flex flex-col md:flex-row">
                    <mat-form-field class="flex-1 px-1">
                        <mat-label class="t-w">Brand</mat-label>
                        <!-- mat select with search -->
                        <mat-select formControlName="brandId">
                            <mat-option *ngFor="let option of brandOptions" [value]="option.value">
                                {{ option.label }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="flex-1 px-1">
                        <mat-label class="t-w">ECU Brand</mat-label>
                        <mat-select formControlName="ecuProducerId"
                                    (selectionChange)="ecuProducerChange($event)">
                            <mat-option *ngFor="let option of ecuProducerOptions" [value]="option.value">
                                {{ option.label }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="flex-1 px-1">
                        <mat-label class="t-w">ECU Model</mat-label>
                        <mat-select formControlName="ecuTypeId">
                            <mat-option *ngFor="let option of ecuTypeOptions" [value]="option.value">
                                {{ option.label }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="flex items-center justify-center mt-4">
                    <button
                        class="ml-4 text-2xl btn-landing"
                        mat-flat-button
                        type="button"
                        color="primary"
                        (click)="search()"
                        [disabled]="!form.valid || isLoadingSpinnerActive">
                        <mat-spinner *ngIf="isLoadingSpinnerActive"></mat-spinner>
                        Search
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
