import {Route} from '@angular/router';
import {AuthGuard} from 'app/core/auth/guards/auth.guard';
import {NoAuthGuard} from 'app/core/auth/guards/noAuth.guard';
import {LayoutComponent} from 'app/layout/layout.component';
import {NotFoundComponent} from '@portal/shared/not-found/not-found.component';
import {HomepageComponent} from "./modules/landing/homepage/homepage.component";
import {SearchComponent} from "./modules/landing/search/search.component";
import {DtcRemovalComponent} from "./modules/landing/dtc-removal/dtc-removal.component";
import {TuningComponent} from "./modules/landing/tuning/tuning.component";
import {SupportedSolutionsComponent} from "./modules/landing/supported-solutions/supported-solutions.component";

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    { path: '', component: LayoutComponent,
        children: [
            {
                path: '',
                component: HomepageComponent,
            },
            {
                path: 'supported-list',
                component: SupportedSolutionsComponent,
            },
            {
                path: 'search',
                component: SearchComponent,
            },
            {
                canActivate: [AuthGuard],
                path: 'dtc-removal',
                component: DtcRemovalComponent,
            },
            {
                canActivate: [AuthGuard],
                path: 'tuning',
                component: TuningComponent,
            },
        ]
    },

    // Redirect signed in user to the '/example'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: '/' },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,

        children: [
            {
                path: 'confirmation-required',
                loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule)
            },
            {
                path: 'confirm-email',
                loadChildren: () => import('app/modules/auth/confirm-email/confirm-email.module').then(m => m.AuthConfirmEmailModule)
            },
            {
                path: 'forgot-password',
                loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)
            },
            {
                path: 'reset-password',
                loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule)
            },
            { path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule) },
            { path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule) },
        ]
    },

    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        children: [
            { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule) },
            {
                path: 'unlock-session',
                loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule)
            }
        ]
    },

    // Auth routes for authenticated users
    {
        path: 'admin',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        loadChildren: () => import('app/modules/admin/admin.module').then(m => m.AdminModule)
    },

    // Landing routes
    {

        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: '', loadChildren: () => import('app/modules/landing/landing.module').then(m => m.LandingModule) },
        ]
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        children: [
        ]
    },
    { path: '**', component: NotFoundComponent },
];
