import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, filter, Observable, of, shareReplay, switchMap, tap } from 'rxjs';
import { Navigation } from 'app/core/navigation/navigation.types';
import { StorageService } from '@portal/shared/services/storage.service';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    private _navigation: BehaviorSubject<Navigation> = new BehaviorSubject<Navigation>(null);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient,
                private _storageService: StorageService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation> {
        return this._navigation.asObservable().pipe(filter(item => item !== null), shareReplay());
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Observable<Navigation> {
        return this.getNavigationList().pipe(
            tap((navigation) => {
                navigation.default = navigation.default.filter(x => x.children.length !== 0);
                this._navigation.next(navigation);
            })
        );
    }

    private setNoPartyMenuItems(navigation: Navigation) {
        navigation.default = navigation.default.map(x => {
            x.children = x.children.filter(c => !c.needParty);
            return x;
        });
    }
    private setAdminMenuItems(navigation: Navigation) {
        const userRoles = this._storageService.getTokenRoles();
        // Filtrar os itens de nível superior com base nas roles do usuário
        navigation.default = navigation.default.filter((item) => {
            // Se o item de nível superior não tiver roles, mantém.
            if (!item.roles || item.roles.length === 0) {
                return true;
            }

            // Verifica se o usuário tem pelo menos uma das roles necessárias para o item de nível superior.
            const hasRequiredRole = item.roles.some(role => userRoles.includes(role));

            // Se o item de nível superior não tiver a role necessária, não precisa verificar os children.
            if (!hasRequiredRole) {
                return false;
            }

            // Se o item de nível superior tiver children, filtra os children também.
            if (item.children) {
                item.children = item.children.filter((child) => {
                    // Se o child não tiver roles, mantém.
                    if (!child.roles || child.roles.length === 0) {
                        return true;
                    }

                    // Verifica se o usuário tem pelo menos uma das roles necessárias para o child.
                    return child.roles.some(role => userRoles.includes(role));
                });
            }

            // Mantém o item de nível superior se passou pelas verificações acima.
            return true;
        });

        // ...
    }


    private setNoOrgMenuItems(navigation: Navigation) {
        navigation.default = navigation.default.map(x => {
            x.children = x.children.filter(c => !c.needOrg);
            return x;
        });
    }

    private filterMenuItemsByReadPermissions(navigation: Navigation, permissionsList: Permissions) {
        navigation.default = navigation.default.map(x => {
            const isAdminMenu = x.type === 'group' && x.id === 'admin';

            if (isAdminMenu) {
                const roles = this._storageService.getTokenRoles();
            }
            x.children = x.children.filter(c => {
                if (isAdminMenu) {
                    return true;
                }
            });

            return x;
        });
        return navigation;
    }
    private getNavigationList(): Observable<Navigation> {
        return this._httpClient.get<Navigation>('api/common/navigation');
    }
}
