import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DeleteDialogComponent} from './delete-dialog/delete-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {CustomDatePipe} from '@portal/shared/pipes/custom-date-pipe';
import {NotFoundComponent} from '@portal/shared/not-found/not-found.component';
import {RouterModule} from '@angular/router';
import {SharedMatTableModule} from "@portal/shared/shared-mat-table.module";
import {MatRadioModule} from "@angular/material/radio";
import {UploadPopupComponent} from "@portal/shared/modals/upload-popup/upload-popup.component";
import {Signature16Pipe} from "@portal/shared/pipes/signature-16-pipe";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatButtonModule,
        RouterModule,
        SharedMatTableModule,
        MatRadioModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CustomDatePipe,
        Signature16Pipe
    ],
    declarations: [
        DeleteDialogComponent,
        CustomDatePipe,
        NotFoundComponent,
        UploadPopupComponent,
        Signature16Pipe
    ]
})
export class SharedModule {
}
