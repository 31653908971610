import {Injectable} from "@angular/core";
import {ApiBaseService} from "@portal/api/api-base-service";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {TuningResponse} from "@portal/api/requests/tuning/tuning-response";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class TuningService extends ApiBaseService {
    controller = 'tuning';

    constructor(
        private _httpClient: HttpClient,
    ) {
        super(_httpClient);
    }

    search(formData: FormData): Observable<TuningResponse[]> {
        return this.postMultipartOptions(`${this.controller}/search`, formData, { responseType: 'json'});
    }

    submit(formData: FormData): Observable<HttpResponse<Blob>> {
        return this.postMultipartOptions(`${this.controller}/submit`, formData, { responseType: 'blob' as 'json', observe: 'response'});
    }
}
