import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ApiBaseService} from "@portal/api/api-base-service";
import {ForgotPasswordRequest} from "@portal/api/requests/auth/forgot-password-request";
import {Observable, of, switchMap} from "rxjs";
import {AuthToken} from "@portal/api/responses/Auth/auth-token";
import {ResetPasswordRequest} from "@portal/api/requests/auth/reset-password-request";
import {LoginResponse} from "@portal/api/responses/Auth/login-response";
import {LoginRequest} from "@portal/api/requests/auth/login-request";
import {EmailConfirmationRequest} from "@portal/api/requests/auth/email-confirmation-request";
import {RegisterNewAccountRequest} from "@portal/api/requests/auth/register-new-account-request";


@Injectable({
    providedIn: 'root'
})
export class AccountService extends ApiBaseService {
    controller = 'Account';

    constructor(
        private _httpClient: HttpClient,
    ) {
        super(_httpClient);
    }

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(request: ForgotPasswordRequest): Observable<any> {
        return this.post<AuthToken, ForgotPasswordRequest>(request, this.controller + `/ForgotPassword`);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(request: ResetPasswordRequest): Observable<any> {
        return this.post<AuthToken, ResetPasswordRequest>(request, this.controller + `/ResetPassword`);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<LoginResponse> {
        return this.post<LoginResponse, LoginRequest>(credentials, this.controller + `/Login`);
    }

    confirmEmail(request: EmailConfirmationRequest): Observable<boolean> {
        return this.post<any, EmailConfirmationRequest>(request, this.controller + `/ConfirmEmail`).pipe(
            switchMap((response: any) => {
                if (response?.error) {
                    return of(false);
                }
                return of(true);
            })
        );
    }

    resendConfirmationEmail(email: string): Observable<boolean> {
        return this.post<any, string>(email, this.controller + `/ResendEmailToken`).pipe(
            switchMap((response: any) => {
                if (response) {
                    return of(true);
                }
                if (response?.error) {
                    return of(false);
                }
            })
        );
    }

    /**
     * Get new access token
     */
    getNewAccessToken(refreshToken: string): Observable<AuthToken> {
        return this.post<AuthToken, null>(null, this.controller + '/RefreshAccessToken?refreshToken=' + refreshToken);
    }

    /**
     * Sign up
     *
     * @param request
     */
    signUp(request: RegisterNewAccountRequest): Observable<any> {
        return this.post<AuthToken, RegisterNewAccountRequest>(request, this.controller + '/RegisterNewAccount');
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

}
