<div class="flex flex-col flex-auto min-w-0">
    <div
        class="flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between p-4 sm:px-10 border-b bg-card dark:bg-transparent">
        <div class="flex-1 min-w-0">
            <h2 class="text-2xl md:text-3xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate">
                Supported List
            </h2>
        </div>
    </div>
    <mat-drawer-container class="form-container" [hasBackdrop]="true">
        <div class="flex my-2">
            <div class="grid grid-cols-1 sm:grid-cols-4 items-center filters ml-auto">
                <div>
                    <label class="mr-2">Brand</label>
                    <mat-form-field class="flex flex-col min-width-select">
                        <mat-select [ngModel]="brandId"
                                    (valueChange)="addFilter('brandId', $event)">
                            <mat-option value="">All</mat-option>
                            <mat-option *ngFor="let option of brandOptions" [value]="option.value">
                                {{option.label}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="ml-0 mt-4 sm:ml-6 sm:mt-0">
                    <label class="mr-2">Ecu Brand</label>
                    <mat-form-field class="flex flex-col min-width-select">
                        <mat-select [ngModel]="ecuProducerId"
                                    (valueChange)="addFilter('ecuProducerId', $event)">
                            <mat-option value="">All</mat-option>
                            <mat-option *ngFor="let option of ecuProducerOptions" [value]="option.value">
                                {{option.label}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="ml-0 mt-4 sm:ml-6 sm:mt-0">
                    <label class="mr-2 ml-4 ">Ecu type</label>
                    <mat-form-field class="flex flex-col min-width-select">
                        <mat-select [(ngModel)]="ecuTypeId"
                                    (valueChange)="addFilter('ecuTypeId', $event)">
                            <mat-option value="">All</mat-option>
                            <mat-option *ngFor="let option of ecuTypeOptions" [value]="option.value">
                                {{option.label}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="ml-0 mt-4 sm:ml-6 sm:mt-0">
                    <span matPrefix class="fake-label">&nbsp;</span>
                    <mat-form-field class="w-full">
                        <span matPrefix> </span>
                        <input matInput placeholder="Pesquisar" name="search" [(ngModel)]="searchValue"
                               (keyup.enter)="search()">
                        <button matSuffix mat-button (click)="search()">
                            <mat-icon>search</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="table-container">
            <div class="spinner-container" *ngIf="dataSource.loading$ | async">
                <mat-spinner></mat-spinner>
            </div>
            <table class="w-full"
                   mat-table
                   multiTemplateDataRows
                   [dataSource]="dataSource">

                <!-- Name Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>Name</th>
                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>

                <ng-container matColumnDef="brand">
                    <th mat-header-cell *matHeaderCellDef>Brand</th>
                    <td mat-cell *matCellDef="let element"> {{element.brand}} </td>
                </ng-container>

                <ng-container matColumnDef="ecuProducer">
                    <th mat-header-cell *matHeaderCellDef>Ecu Brand</th>
                    <td mat-cell *matCellDef="let element"> {{element.ecuProducer}} </td>
                </ng-container>

                <ng-container matColumnDef="ecuType">
                    <th mat-header-cell *matHeaderCellDef>Ecu Model</th>
                    <td mat-cell *matCellDef="let element"> {{element.ecuType}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                <tr class="cursor-pointer" mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
            </table>

            <!--            <mat-paginator [pageSizeOptions]="[30, 50, 100]"-->
            <!--                           [length]="dataSource.totalItems"-->
            <!--                           [pageSize]="defaultTableSize"-->
            <!--                           (page)="load()"-->
            <!--                           showFirstLastButtons-->
            <!--                           class="paginator-bottom">-->
            <!--            </mat-paginator>-->
        </div>
    </mat-drawer-container>
</div>
