import {Component, OnInit, ViewChild} from '@angular/core';
import {SupportedSolutionsService} from "@portal/api/services/supported-solutions";
import {SupportedSolutionsResponse} from "@portal/api/contracts/supported-solutions/supported-solutions-response";
import {BaseDataSource} from "@portal/shared/base-data-source/base-data-source";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {environment} from "../../../../environments/environment";
import {DtcConfigurationListResponse} from "@portal/api/responses/dtc-configuration/dtc-configuration-list-response";
import {SearchRequestFilter} from "@portal/api/requests/search-request-filter";
import {SearchRequest} from "@portal/api/requests/search-request";
import {EcuProducersService} from "@portal/api/services/ecu-producers.service";
import {EcuTypesService} from "@portal/api/services/ecu-types.service";
import {BrandsService} from "@portal/api/services/brands.service";

@Component({
    selector: 'app-supported-solutions',
    templateUrl: './supported-solutions.component.html',
    styleUrls: ['./supported-solutions.component.scss']
})
export class SupportedSolutionsComponent implements OnInit {
    columnsToDisplay: string[];
    defaultTableSize: number;
    dataSource: BaseDataSource<SupportedSolutionsResponse>;
    filters: SearchRequestFilter[];
    // Filters
    ecuProducerOptions: { label: string, value: string }[] = []
    brandOptions: { label: string, value: string }[] = []
    ecuTypeOptions: { label: string, value: string }[] = []
    brandId: string;
    ecuProducerId: string;
    ecuTypeId: string;
    searchValue: string;

    constructor(
        private _supportedSolutionsService: SupportedSolutionsService,
        private _ecuProducerService: EcuProducersService,
        private _ecuTypesService: EcuTypesService,
        private _brandsService: BrandsService) {
    }

    ngOnInit(): void {
        this.defaultTableSize = environment.defaultTableSize;
        this.columnsToDisplay = ['name', 'brand', 'ecuProducer', 'ecuType'];
        this.dataSource = new BaseDataSource<DtcConfigurationListResponse>(this._supportedSolutionsService);
        this.dataSource.load('asc', 'name', 0, this.defaultTableSize, false, this.filters, true);
        this._brandsService.list({
            getAll: true
        } as SearchRequest).subscribe(brands => {
            // order by name
            brands.items.sort((a, b) => a.name.localeCompare(b.name));
            this.brandOptions = brands.items.map(brand => {
                return {
                    label: brand.name,
                    value: brand.id.toString()
                };
            });
        });

        this._ecuProducerService.list({
            getAll: true
        } as SearchRequest).subscribe(ecuProducers => {
            // order by name
            ecuProducers.items.sort((a, b) => a.name.localeCompare(b.name));
            this.ecuProducerOptions = ecuProducers.items.map(ecuProducer => {
                return {
                    label: ecuProducer.name,
                    value: ecuProducer.id.toString()
                };
            });
        });

        this.filters = [];
        this.brandId = '';
        this.ecuProducerId = '';
        this.ecuTypeId = '';

    }

    addFilter(value: string, event: string) {
        this.filters = this.filters.filter(x => x.code.toLowerCase() !== value.toLowerCase());
        if (event) {
            this.filters.push({code: value, values: [event]});
        }
        if (value === 'ecuProducerId') {
            if (event === '') {
                this.ecuTypeOptions = [];
                this.filters = this.filters.filter(x => x.code.toLowerCase() !== 'ecuTypeId'.toLowerCase());
                this.ecuTypeId = '';
            } else {
                this._ecuTypesService.list({
                    getAll: true,
                    filters: [{code: 'ecuproducerid', values: [event]}]
                } as SearchRequest).subscribe(ecuTypes => {
                    // order by name
                    ecuTypes.items.sort((a, b) => a.name.localeCompare(b.name));
                    this.ecuTypeOptions = ecuTypes.items.map(ecuType => {
                        return {
                            label: ecuType.name,
                            value: ecuType.id.toString()
                        };
                    });
                });
            }
        }
        this.load(true);
    }

    search() {
        this.filters = this.filters.filter(filter => filter.code !== "search");
        if (this.searchValue && this.searchValue.trim()) {
            this.filters.push({code: "search", values: [this.searchValue.trim()]});
        }
        this.load(true);
    }

    load(refresh = false) {
        this.dataSource.load('asc', 'brand', 0, 10, refresh, this.filters, true);
    }
}
