<h1 mat-dialog-title class="custom-dialog-title">{{data.title}}</h1>
<div mat-dialog-content class="text-center">
    {{data.description}} <span *ngIf="data.item">"{{data.item}}"</span>
</div>
<div mat-dialog-actions class="custom-dialog-actions">
    <button mat-flat-button
            type="button"
            [color]="'warn'"
            [mat-dialog-close]="false"
            *ngIf="data.hasCancelButton">
        Cancel
    </button>
    <button mat-flat-button
            type="button"
            [color]="'primary'"
            [mat-dialog-close]="true"
            cdkFocusInitial
            *ngIf="data.hasOkButton">Ok
    </button>
    <button mat-flat-button
            type="button"
            [color]="'primary'"
            [mat-dialog-close]="true"
            cdkFocusInitial
            *ngIf="data.hasYesButton"
    >Yes
    </button>
    <button mat-flat-button
            [color]="'warn'"
            [mat-dialog-close]="false"
            *ngIf="data.hasNoButton">No
    </button>
</div>
